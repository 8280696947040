.main_projects {
	&__item {
		float: left;
		width: 33.333%;
		padding: 0 20px;
		margin-top: 40px;
		position: relative;
		&:hover {
			.main_projects {
				&__overlay {
					opacity: 1;
					visibility: visible;
				}
				&__thumb {
					img {
						transform: scale(1.05);
					}
				}
			}
		}
	}
	&__overlay {
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		display: flex;
		z-index: 1111;
		visibility: hidden;
		position: absolute;
		align-items: center;
		justify-content: center;
		transition: $transition1;
		background: rgba(255,255,255,0.6);
		h3 {
			color: black;
			font-size: 22px;
			text-transform: uppercase;
		}
	}
	&__thumb {
		overflow: hidden;
		img {
			width: 100%;
			height: 60vh;
			object-fit: cover;
			transition: $transition1;
		}
	}
}
.centered_container {
	width: 100%;
	margin: 0 auto;
	max-width: 770px;
}
.article_container {
	width: 100%;
	margin: 0 auto;
	max-width: 1170px;
}
.centered_content {
	width: 100%;
	margin-top: 120px;
	p {
		color:#000;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.7em;
		margin-bottom: 20px;
	}
}
.article {
	margin-top: 40px;
	&__title {
		width: 100%;
		font-size: 28px;
		text-align: center;
		line-height: 1.4em;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
	&__description {
		color:#000;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.7em;
		margin-bottom: 20px;
		text-align: justify;
	}
	&__pictures {
		width: 100%;
		float: left;
		padding: 0 2.5px;
		.row {
			margin-left: -5px;
			margin-right: -5px;
		}
		.chocolat-image {
			float: left;
			width: 33.333%;
			cursor: pointer;
			padding-left: 2.5px;
			padding-right: 2.5px;
			margin-bottom: 2.5px;
			img {
				width: 100%;
				height: 350px;
				object-fit: cover;
				transition: $transition1;
			}
		}
	}
	&__shares {
		float: left;
		width: 100%;
		display: flex;
		margin-top: 50px;
		align-items: center;
		padding-bottom: 50px;
		border-bottom: 1px solid #dfe5e8;
		&__title {
			float: left;
			font-size: 18px;
			font-weight: bold;
			color: $fontColor;
			margin-right: 10px;
		}
		&__list {
			float: left;
			li {
				width: 30px;
				height: 30px;
				border-radius: 100%;
				display: inline-block;
				border: 1px solid #dfe5e8;
				a {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			img {
				height: 15px;
			}
		}
	}
}

.chocolat-wrapper {
	.chocolat-left {
		background: url('../images/left.png') no-repeat center;
	}
	.chocolat-right {
		background: url('../images/right.png') no-repeat center;
	}
	.chocolat-bottom {
		background: rgba(0,0,0,0.6);
	}
	.chocolat-overlay {
		background: black !important;
	}
}

.error_message {
	width: 100%;
	display: flex;
	min-height: 55vh;
	align-items: center;
	justify-content: center;
	h2 {
		width: auto;
		font-size: 22px;
	}
}

.contact {
	float: left;
	width: 100%;
	&__map {
		float: left;
		width: 100%;
		height: 500px;
		background: $accent;
	}
	&__information {
		float: left;
		width: 100%;
		margin-top: 120px;
		&__title {
			float: left;
			width: 100%;
			font-size: 18px;
			font-weight: bold;
			color: $fontColor;
			margin-bottom: 20px;
			letter-spacing: 1.1px;
			text-transform: uppercase;
		}
	}
	&__form {
		width: 60%;
		float: left;
		&__left_side {
			width: 35%;
			float: left;
		}
		&__right_side {
			width: 65%;
			float: left;
			padding-left: 20px;
		}
		&__submit {
			float: left;
			width: 100%;
		}
		input {
			float: left;
			width: 100%;
			padding: 15px 10px;
			background: #f5f5f5;
			margin-bottom: 20px;
			border: solid 1px #ddd;
			@include placeholder {
				color: #8d8d9a;
				font-size: 12px;
				font-weight: bold;
			}
			&[type="submit"] {
				width: auto;
				margin-bottom: 0;
				padding: 10px 40px;
				text-transform: uppercase;
			}
		}
		textarea {
			width: 100%;
			resize: none;
			padding: 10px;
			height: 183px;
			font-size: 12px;
			font-weight: bold;
			background: #f5f5f5;
			border: solid 1px #ddd;
			@include placeholder {
				color: #8d8d9a;
				font-size: 12px;
				font-weight: bold;
			}
		}
	}
	&__other_info {
		width: 40%;
		float: left;
		padding-left: 20px;
		padding-right: 15%;
	}
	&__other_list {
		float: left;
		width: 100%;
		li {
			float: left;
			width: 100%;
			margin-bottom: 7px;
			padding-bottom: 7px;
			border-bottom: solid 1px #ddd;
		}
		h3 {
			float: left;
			width: 100%;
			color: $fontColor;
			span {
				width: 22%;
				color: $accent;
				font-weight: bold;
				display: inline-block;
			}
		}
	}
}

div.wpcf7-validation-errors {
	padding: 0;
	float: left;
	width: 100%;
	margin-left: 0;
	border: none !important;
}