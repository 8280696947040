.footerWrap {
	float: left;
	width: 100%;
	position: relative;
}
.main_footer {
	padding: 30px 0;
	margin-top: 60px;
	background: black;
	&--contact_us {
		padding-top: 0 !important;
		.contact_us_section {
			margin-bottom: 30px;
		}
	}
	.copyright {
		float: left;
		color: white;
		font-size: 12px;
	}
}
.contact_us_section {
	float: left;
	width: 100%;
	display: flex;
	padding: 50px 0;
	background: $accent;
	align-items: center;
	justify-content: center;
	a {
		font-size: 14px;
		font-weight: bold;
		padding: 10px 30px;
		letter-spacing: 1px;
		text-decoration: none;
		border: solid 1px #fff;
		color: white !important;
		transition: $transition1;
		text-transform: uppercase;
		&:hover {
			background: black;
		}
		&:after {
			content: '';
			width: 20px;
			height: 15px;
			float: right;
			margin-left: 15px;
			background: url('../images/chevron-right.svg') no-repeat center;
			background-size: contain;
		}
	}
}
.footer_social_media {
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	li {
		margin-right: 15px;
		display: inline-block;
		transition: $transition1;
		&:hover {
			opacity: 0.7;
		}
		&:last-child {
			margin-right: 0;
		}
		img {
			height: 20px;
		}
	}
}