.chocolat-zoomable.chocolat-zoomed {
  cursor: zoom-out;
}

.chocolat-open {
  overflow: hidden;
}

.chocolat-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: #fff;
  display: none;
  opacity: 0.8;
}

.chocolat-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  z-index: 16;
  color: #fff;
}

.chocolat-zoomable .chocolat-img {
  cursor: zoom-in;
}

.chocolat-loader {
  height: 32px;
  width: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px;
  z-index: 11;
  background: url(../images/loader.gif);
  display: none;
}

.chocolat-content {
  position: fixed;
  width: 0px;
  height: 0px;
  left: 50%;
  top: 50%;
  z-index: 14;
  text-align: left;
}

.chocolat-content .chocolat-img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.chocolat-wrapper .chocolat-left {
  position: absolute;
  left: 0;
  width: 50px;
  height: 100px;
  top: 50%;
  margin-top: -50px;
  cursor: pointer;
  background: url(../images/left.png) 50% 50% no-repeat;
  z-index: 17;
  display: none;
}

.chocolat-wrapper .chocolat-right {
  position: absolute;
  right: 0;
  width: 50px;
  height: 100px;
  top: 50%;
  margin-top: -50px;
  cursor: pointer;
  background: url(../images/right.png) 50% 50% no-repeat;
  z-index: 17;
  display: none;
}

.chocolat-wrapper .chocolat-right.active {
  display: block;
}

.chocolat-wrapper .chocolat-left.active {
  display: block;
}

.chocolat-wrapper .chocolat-top {
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
  line-height: 50px;
  height: 50px;
  overflow: hidden;
  z-index: 17;
  margin-bottom: 10px;
}

.chocolat-wrapper .chocolat-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/close.png) 50% 50% no-repeat;
}

.chocolat-wrapper .chocolat-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 40px;
  height: 40px;
  font-size: 12px;
  z-index: 17;
  padding-left: 15px;
  padding-right: 15px;
  background: rgba(0, 0, 0, 0.2);
  text-align: right;
  margin-top: 10px;
}

.chocolat-wrapper .chocolat-set-title {
  display: inline-block;
  padding-right: 15px;
  line-height: 1;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.chocolat-wrapper .chocolat-pagination {
  float: right;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 15px;
  /*border-right: 1px solid rgba(255, 255, 255, 0.2);*/
}

.chocolat-wrapper .chocolat-fullscreen {
  width: 16px;
  height: 40px;
  background: url(../images/fullscreen.png) 50% 50% no-repeat;
  display: block;
  margin: auto;
  cursor: pointer;
  float: right;
}

.chocolat-wrapper .chocolat-description {
  display: inline-block;
  float: left;
}

/* no container mode*/
body.chocolat-open > .chocolat-overlay {
  z-index: 15;
}

body.chocolat-open > .chocolat-loader {
  z-index: 15;
}

body.chocolat-open > .chocolat-content {
  z-index: 17;
}

/* container mode*/
.chocolat-in-container .chocolat-wrapper,
.chocolat-in-container .chocolat-content,
.chocolat-in-container .chocolat-overlay {
  position: absolute;
}

.chocolat-in-container {
  position: relative;
}

/* uncomment to hide controls when zoomed-in*/
/*
.chocolat-zoomable .chocolat-top,
.chocolat-zoomable .chocolat-bottom,
.chocolat-zoomable .chocolat-right,
.chocolat-zoomable .chocolat-left {
    transition: opacity .3s ease, visibility 0s .3s;
   opacity: 1;
}

.chocolat-zoomable.chocolat-zoomed .chocolat-top,
.chocolat-zoomable.chocolat-zoomed .chocolat-bottom,
.chocolat-zoomable.chocolat-zoomed .chocolat-right,
.chocolat-zoomable.chocolat-zoomed .chocolat-left {
    visibility: hidden;
    opacity: 0;
}
*/
/*====================================
=            GRID GLOBALS            =
====================================*/
/*=====  End of GRID GLOBALS  ======*/
/*===================================
=            TRANSITIONS            =
===================================*/
/*=====  End of TRANSITIONS  ======*/
/*=================================
=            STRUCTURE            =
=================================*/
/*=====  End of STRUCTURE  ======*/
/*==============================
=            COLORS            =
==============================*/
/*=====  End of COLORS  ======*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

header,
main,
footer {
  float: left;
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

*:after, *:before {
  box-sizing: border-box;
}

.headerWrap,
.mainWrap,
.footerWrap {
  position: relative;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
div,
ul,
input,
blockquote,
span,
button,
i,
b,
em,
strong {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

body {
  overflow-x: hidden;
  position: relative;
}

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/*=====  End of MEDIA QUERIES  ======*/
/*==============================
=            MIXINS            =
==============================*/
/*=====  End of MIXINS  ======*/
/*========================================
=            EXTEND SELECTORS            =
========================================*/
.container,
.row {
  *zoom: 1;
}

.container:before,
.row:before, .container:after,
.row:after {
  content: " ";
  display: table;
}

.container:after,
.row:after {
  clear: both;
}

.headerWrap,
.mainWrap,
.footerWrap {
  float: left;
  width: 100%;
  clear: both;
}

/*=====  End of EXTEND SELECTORS  ======*/
.column-1 {
  width: 8.33333%;
}

.column-2 {
  width: 16.66667%;
}

.column-3 {
  width: 25%;
}

.column-4 {
  width: 33.33333%;
}

.column-5 {
  width: 41.66667%;
}

.column-6 {
  width: 50%;
}

.column-7 {
  width: 58.33333%;
}

.column-8 {
  width: 66.66667%;
}

.column-9 {
  width: 75%;
}

.column-10 {
  width: 83.33333%;
}

.column-11 {
  width: 91.66667%;
}

.column-12 {
  width: 100%;
}

.wow {
  visibility: hidden;
}

[class*='column-'] {
  float: left;
  min-height: 1px;
}

.has_gutter [class*='column-'] {
  padding: 0 20px;
}

.row {
  margin-left: -20px;
  margin-right: -20px;
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .container {
    max-width: 732px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .container {
    max-width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media only screen and (min-width: 1500px) {
  .container {
    max-width: 1400px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .show-mob {
    display: none !important;
  }
  .show-tab {
    display: none !important;
  }
  .show-tab-big {
    display: none !important;
  }
}

.hidden-mob {
  display: block;
}

.hidden {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .column-mob-1 {
    width: 8.33333%;
  }
  .column-mob-2 {
    width: 16.66667%;
  }
  .column-mob-3 {
    width: 25%;
  }
  .column-mob-4 {
    width: 33.33333%;
  }
  .column-mob-5 {
    width: 41.66667%;
  }
  .column-mob-6 {
    width: 50%;
  }
  .column-mob-7 {
    width: 58.33333%;
  }
  .column-mob-8 {
    width: 66.66667%;
  }
  .column-mob-9 {
    width: 75%;
  }
  .column-mob-10 {
    width: 83.33333%;
  }
  .column-mob-11 {
    width: 91.66667%;
  }
  .column-mob-12 {
    width: 100%;
  }
  .show-mob {
    display: block !important;
  }
  .hidden-mob {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .column-tab-1 {
    width: 8.33333%;
  }
  .column-tab-2 {
    width: 16.66667%;
  }
  .column-tab-3 {
    width: 25%;
  }
  .column-tab-4 {
    width: 33.33333%;
  }
  .column-tab-5 {
    width: 41.66667%;
  }
  .column-tab-6 {
    width: 50%;
  }
  .column-tab-7 {
    width: 58.33333%;
  }
  .column-tab-8 {
    width: 66.66667%;
  }
  .column-tab-9 {
    width: 75%;
  }
  .column-tab-10 {
    width: 83.33333%;
  }
  .column-tab-11 {
    width: 91.66667%;
  }
  .column-tab-12 {
    width: 100%;
  }
  .show-tab {
    display: block !important;
  }
  .hidden-tab {
    display: none !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .column-tab-big-1 {
    width: 8.33333%;
  }
  .column-tab-big-2 {
    width: 16.66667%;
  }
  .column-tab-big-3 {
    width: 25%;
  }
  .column-tab-big-4 {
    width: 33.33333%;
  }
  .column-tab-big-5 {
    width: 41.66667%;
  }
  .column-tab-big-6 {
    width: 50%;
  }
  .column-tab-big-7 {
    width: 58.33333%;
  }
  .column-tab-big-8 {
    width: 66.66667%;
  }
  .column-tab-big-9 {
    width: 75%;
  }
  .column-tab-big-10 {
    width: 83.33333%;
  }
  .column-tab-big-11 {
    width: 91.66667%;
  }
  .column-tab-big-12 {
    width: 100%;
  }
  .show-tablet-big {
    display: block !important;
  }
  .hidden-tablet-big {
    display: none !important;
  }
}

.is-full-width {
  width: 100vw !important;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  max-width: none;
}

.no_padding {
  padding: 0 !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

.no-flexboxlegacy body {
  display: block !important;
}

body {
  font-family: 'Montserrat', BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  font-size: 14px;
  color: black;
  position: relative;
  overflow-x: hidden;
}

input,
textarea,
select,
option {
  font-family: 'Montserrat', BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder,
option::-webkit-input-placeholder {
  font-family: 'Montserrat', BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder,
option:-moz-placeholder {
  font-family: 'Montserrat', BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder,
option::-moz-placeholder {
  font-family: 'Montserrat', BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder,
option:-ms-input-placeholder {
  font-family: 'Montserrat', BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

.iphone input,
.iphone textarea,
.iphone select,
.iphone option {
  appearance: none;
  border-radius: 0;
}

select::-ms-expand {
  display: none;
}

/*=================================
=            Normalize            =
=================================*/
mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

/* Do not show the outline on the skip link target. */
#main[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*=====  End of Normalize  ======*/
.main_header {
  padding: 20px 0;
  box-shadow: 0px 3px 56px 0px #b5b5b5;
}

.logo {
  float: left;
}

.logo a {
  float: left;
  line-height: 1em;
}

.logo img {
  max-height: 40px;
}

.header_nav {
  float: right;
  padding-top: 10px;
}

.header_nav ul {
  float: left;
  padding-left: 35px;
  padding-right: 35px;
  border-left: 1px solid #e0c8c6;
}

.header_nav ul:first-child {
  border-left: 0;
  padding-left: 0;
}

.header_nav ul:last-child {
  padding-right: 0;
}

.header_nav li {
  margin-right: 20px;
  display: inline-block;
  list-style-type: none;
}

.header_nav li:last-child {
  margin-right: 0;
}

.header_nav li a {
  font-size: 12px;
  color: #5c5c5c;
  letter-spacing: 1.5px;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  text-transform: uppercase;
}

.header_nav li:hover a, .header_nav li.current-menu-item a, .header_nav li.active a {
  color: #e0c8c6;
}

.hamburger {
  z-index: 5;
  width: 30px;
  float: right;
  height: auto;
  display: none;
  cursor: pointer;
  margin-top: 5px;
  position: relative;
}

.hamburger span {
  width: 100%;
  height: 4px;
  float: right;
  margin-bottom: 4px;
  border-radius: 4px;
  background: #e0c8c6;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.hamburger span:last-child {
  margin-bottom: 0;
}

.hamburger.active span:first-child {
  margin-top: 7px;
  margin-bottom: 2px;
  transform: rotate(45deg);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  margin-top: -14px;
  transform: rotate(-45deg);
}

.main_projects__item {
  float: left;
  width: 33.333%;
  padding: 0 20px;
  margin-top: 40px;
  position: relative;
}

.main_projects__item:hover .main_projects__overlay {
  opacity: 1;
  visibility: visible;
}

.main_projects__item:hover .main_projects__thumb img {
  transform: scale(1.05);
}

.main_projects__overlay {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1111;
  visibility: hidden;
  position: absolute;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  background: rgba(255, 255, 255, 0.6);
}

.main_projects__overlay h3 {
  color: black;
  font-size: 22px;
  text-transform: uppercase;
}

.main_projects__thumb {
  overflow: hidden;
}

.main_projects__thumb img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.centered_container {
  width: 100%;
  margin: 0 auto;
  max-width: 770px;
}

.article_container {
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
}

.centered_content {
  width: 100%;
  margin-top: 120px;
}

.centered_content p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7em;
  margin-bottom: 20px;
}

.article {
  margin-top: 40px;
}

.article__title {
  width: 100%;
  font-size: 28px;
  text-align: center;
  line-height: 1.4em;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.article__description {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7em;
  margin-bottom: 20px;
  text-align: justify;
}

.article__pictures {
  width: 100%;
  float: left;
  padding: 0 2.5px;
}

.article__pictures .row {
  margin-left: -5px;
  margin-right: -5px;
}

.article__pictures .chocolat-image {
  float: left;
  width: 33.333%;
  cursor: pointer;
  padding-left: 2.5px;
  padding-right: 2.5px;
  margin-bottom: 2.5px;
}

.article__pictures .chocolat-image img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.article__shares {
  float: left;
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  padding-bottom: 50px;
  border-bottom: 1px solid #dfe5e8;
}

.article__shares__title {
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #5c5c5c;
  margin-right: 10px;
}

.article__shares__list {
  float: left;
}

.article__shares__list li {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: inline-block;
  border: 1px solid #dfe5e8;
}

.article__shares__list li a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.article__shares__list img {
  height: 15px;
}

.chocolat-wrapper .chocolat-left {
  background: url("../images/left.png") no-repeat center;
}

.chocolat-wrapper .chocolat-right {
  background: url("../images/right.png") no-repeat center;
}

.chocolat-wrapper .chocolat-bottom {
  background: rgba(0, 0, 0, 0.6);
}

.chocolat-wrapper .chocolat-overlay {
  background: black !important;
}

.error_message {
  width: 100%;
  display: flex;
  min-height: 55vh;
  align-items: center;
  justify-content: center;
}

.error_message h2 {
  width: auto;
  font-size: 22px;
}

.contact {
  float: left;
  width: 100%;
}

.contact__map {
  float: left;
  width: 100%;
  height: 500px;
  background: #e0c8c6;
}

.contact__information {
  float: left;
  width: 100%;
  margin-top: 120px;
}

.contact__information__title {
  float: left;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: #5c5c5c;
  margin-bottom: 20px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
}

.contact__form {
  width: 60%;
  float: left;
}

.contact__form__left_side {
  width: 35%;
  float: left;
}

.contact__form__right_side {
  width: 65%;
  float: left;
  padding-left: 20px;
}

.contact__form__submit {
  float: left;
  width: 100%;
}

.contact__form input {
  float: left;
  width: 100%;
  padding: 15px 10px;
  background: #f5f5f5;
  margin-bottom: 20px;
  border: solid 1px #ddd;
}

.contact__form input::-webkit-input-placeholder {
  color: #8d8d9a;
  font-size: 12px;
  font-weight: bold;
}

.contact__form input:-moz-placeholder {
  color: #8d8d9a;
  font-size: 12px;
  font-weight: bold;
}

.contact__form input::-moz-placeholder {
  color: #8d8d9a;
  font-size: 12px;
  font-weight: bold;
}

.contact__form input:-ms-input-placeholder {
  color: #8d8d9a;
  font-size: 12px;
  font-weight: bold;
}

.contact__form input[type="submit"] {
  width: auto;
  margin-bottom: 0;
  padding: 10px 40px;
  text-transform: uppercase;
}

.contact__form textarea {
  width: 100%;
  resize: none;
  padding: 10px;
  height: 183px;
  font-size: 12px;
  font-weight: bold;
  background: #f5f5f5;
  border: solid 1px #ddd;
}

.contact__form textarea::-webkit-input-placeholder {
  color: #8d8d9a;
  font-size: 12px;
  font-weight: bold;
}

.contact__form textarea:-moz-placeholder {
  color: #8d8d9a;
  font-size: 12px;
  font-weight: bold;
}

.contact__form textarea::-moz-placeholder {
  color: #8d8d9a;
  font-size: 12px;
  font-weight: bold;
}

.contact__form textarea:-ms-input-placeholder {
  color: #8d8d9a;
  font-size: 12px;
  font-weight: bold;
}

.contact__other_info {
  width: 40%;
  float: left;
  padding-left: 20px;
  padding-right: 15%;
}

.contact__other_list {
  float: left;
  width: 100%;
}

.contact__other_list li {
  float: left;
  width: 100%;
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: solid 1px #ddd;
}

.contact__other_list h3 {
  float: left;
  width: 100%;
  color: #5c5c5c;
}

.contact__other_list h3 span {
  width: 22%;
  color: #e0c8c6;
  font-weight: bold;
  display: inline-block;
}

div.wpcf7-validation-errors {
  padding: 0;
  float: left;
  width: 100%;
  margin-left: 0;
  border: none !important;
}

.footerWrap {
  float: left;
  width: 100%;
  position: relative;
}

.main_footer {
  padding: 30px 0;
  margin-top: 60px;
  background: black;
}

.main_footer--contact_us {
  padding-top: 0 !important;
}

.main_footer--contact_us .contact_us_section {
  margin-bottom: 30px;
}

.main_footer .copyright {
  float: left;
  color: white;
  font-size: 12px;
}

.contact_us_section {
  float: left;
  width: 100%;
  display: flex;
  padding: 50px 0;
  background: #e0c8c6;
  align-items: center;
  justify-content: center;
}

.contact_us_section a {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-decoration: none;
  border: solid 1px #fff;
  color: white !important;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  text-transform: uppercase;
}

.contact_us_section a:hover {
  background: black;
}

.contact_us_section a:after {
  content: '';
  width: 20px;
  height: 15px;
  float: right;
  margin-left: 15px;
  background: url("../images/chevron-right.svg") no-repeat center;
  background-size: contain;
}

.footer_social_media {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.footer_social_media li {
  margin-right: 15px;
  display: inline-block;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.footer_social_media li:hover {
  opacity: 0.7;
}

.footer_social_media li:last-child {
  margin-right: 0;
}

.footer_social_media li img {
  height: 20px;
}

@media only screen and (max-width: 767px) {
  .activeNav .header_nav {
    margin-top: 20px;
    max-height: 9999px;
  }
  .header_nav {
    padding: 0;
    float: left;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  }
  .header_nav ul {
    padding: 0;
    width: 100%;
    border: none;
  }
  .header_nav ul.language {
    float: right;
    text-align: right;
  }
  .header_nav ul.language li {
    width: auto;
    border: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-left: 20px;
    padding-bottom: 0;
    display: inline-block;
  }
  .header_nav li {
    width: 100%;
    margin-right: 0;
    padding-left: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #333;
  }
  .hamburger {
    display: block;
  }
  .logo img {
    max-height: 30px;
  }
  .main_projects {
    padding: 0 10px;
  }
  .main_projects__item {
    width: 50%;
    padding: 0 10px;
    margin-top: 20px;
  }
  .main_projects__thumb img {
    height: 170px;
  }
  .main_footer {
    padding: 20px 0;
    margin-top: 40px;
  }
  .copyright {
    width: 100%;
    text-align: center;
  }
  .footer_social_media {
    width: 100%;
    display: flex;
    transform: none;
    position: static;
    margin-top: 30px;
    justify-content: center;
  }
  .centered_content {
    margin-top: 40px;
  }
  .centered_container {
    padding: 0 15px;
  }
  .article {
    margin-top: 20px;
  }
  .article_container {
    padding: 0 15px;
  }
  .article__pictures .chocolat-image {
    width: 50%;
  }
  .article__pictures .chocolat-image img {
    height: 170px;
  }
  .contact__map {
    height: 270px;
  }
  .contact__form {
    width: 100%;
  }
  .contact__other_info {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
}
