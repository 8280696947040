@include mobile {
	.activeNav {
		.header_nav {
			margin-top: 20px;
			max-height: 9999px;
		}
	}
	.header_nav {
		padding: 0;
		float: left;
		width: 100%;
		max-height: 0;
		overflow: hidden;
		transition: $transition1;
		ul {
			padding: 0;
			width: 100%;
			border: none;
			&.language {
				float: right;
				text-align: right;
				li {
					width: auto;
					border: none;
					padding-left: 0;
					margin-bottom: 0;
					margin-left: 20px;
					padding-bottom: 0;
					display: inline-block;
				}
			}
		}
		li {
			width: 100%;
			margin-right: 0;
			padding-left: 20px;
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid #333;
		}
	}
	.hamburger {
		display: block;
	}
	.logo {
		img {
			max-height: 30px;
		}
	}
	.main_projects {
		padding: 0 10px;
		&__item {
			width: 50%;
			padding: 0 10px;
			margin-top: 20px;
		}
		&__thumb {
			img {
				height: 170px;
			}
		}
	}
	.main_footer {
		padding: 20px 0;
		margin-top: 40px;
	}
	.copyright {
		width: 100%;
		text-align: center;
	}
	.footer_social_media {
		width: 100%;
		display: flex;
		transform: none;
		position: static;
		margin-top: 30px;
		justify-content: center;
	}
	.centered_content {
		margin-top: 40px;
	}
	.centered_container {
		padding: 0 15px;
	}
	.article {
		margin-top: 20px;
		&_container {
			padding: 0 15px;
		}
		&__pictures {
			.chocolat-image {
				width: 50%;
				img {
					height: 170px;
				}
			}
		}
	}
	.contact {
		&__map {
			height: 270px;
		}
		&__form {
			width: 100%;
		}
		&__other_info {
			width: 100%;
			padding-left: 0;
			margin-top: 20px;
		}
	}
}