.main_header {
	padding: 20px 0;
	box-shadow: 0px 3px 56px 0px rgba(181,181,181,1);
}
.logo {
	float: left;
	a {
		float: left;
		line-height: 1em;
	}
	img {
		max-height: 40px;
	}
}
.header_nav {
	float: right;
	padding-top: 10px;
	ul {
		float: left;
		padding-left: 35px;
		padding-right: 35px;
		border-left: 1px solid $accent;
		&:first-child {
			border-left: 0;
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
	li {
		margin-right: 20px;
		display: inline-block;
		list-style-type: none;
		&:last-child {
			margin-right: 0;
		}
		a {
			font-size: 12px;
			color: $fontColor;
			letter-spacing: 1.5px;
			text-decoration: none;
			transition: $transition1;
			text-transform: uppercase;
		}
		&:hover,
		&.current-menu-item,
		&.active {
			a {
				color: $accent;
			}
		}
	}
}
.hamburger {
    z-index: 5;
    width: 30px;
    float: right;
    height: auto;
    display: none;
    cursor: pointer;
    margin-top: 5px;
    position: relative;
    span {
        width: 100%;
        height: 4px;
        float: right;
        margin-bottom: 4px;
        border-radius: 4px;
        background: $accent;
        transition: $transition1;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.active {
        span {
            &:first-child {
                margin-top: 7px;
                margin-bottom: 2px;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                margin-top: -14px;
                transform: rotate(-45deg);
            }
        }
    }
}